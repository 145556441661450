import { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
if (!BACKEND_URL) {
  console.error('REACT_APP_BACKEND_URL is not defined in the environment');
}

export const useGoogleAuth = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        if (!BACKEND_URL) {
          throw new Error('Backend URL is not defined');
        }
        const result = await fetch(`${BACKEND_URL}/api/store-token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: response.access_token }),
          credentials: 'include'
        });
        if (result.ok) {
          setIsAuthorized(true);
        } else {
          throw new Error('Failed to store token on server');
        }
      } catch (error) {
        console.error('Auth error:', error);
        setError('Failed to authenticate');
      }
    },
    onError: (error) => {
      console.error('Login Failed:', error);
      setError('Google login failed');
    },
    scope: 'https://www.googleapis.com/auth/calendar.readonly',
  });

  return { isAuthorized, login, error };
};