// src/components/GoogleAuthButton.tsx
import React from 'react';

interface GoogleAuthButtonProps {
  onLogin: () => void;
  isAuthorized: boolean;
}

export const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({ onLogin, isAuthorized }) => {
  return (
    <button
      onClick={onLogin}
      className={`text-white font-bold py-3 px-6 rounded-lg mb-4 hover:bg-blue-800 transition duration-300 ${
        isAuthorized
          ? 'bg-blue-400'
          : 'bg-blue-600'
      }`}
      disabled={isAuthorized}
    >
      {isAuthorized ? 'Authorized' : 'Authorize Google Calendar - IT\'S FREE! →'}
    </button>
  );
};