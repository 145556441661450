// src/App.tsx
import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AppContent } from './components/AppContent';

const App: React.FC = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
            <AppContent />
    </GoogleOAuthProvider>
  );
};

export default App;