// src/components/AppContent.tsx
import React, { useState, useCallback } from 'react';
import { DateRangePicker } from './DateRangePicker';
import { GoogleAuthButton } from './GoogleAuthButton';
import { useGoogleAuth } from '../hooks/useGoogleAuth';
import { ChevronDown, ChevronUp, Clock, FileSpreadsheet, ShieldCheck, LucideIcon } from 'lucide-react';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

if (!BACKEND_URL) {
  console.error('REACT_APP_BACKEND_URL is not defined in the environment');
}

interface FaqItem {
  question: string;
  answer: string;
}

interface Benefit {
  icon: React.ReactElement<LucideIcon>;
  text: string;
}

export const AppContent: React.FC = () => {
  const { isAuthorized, login, error: authError } = useGoogleAuth();
  const [isExporting, setIsExporting] = useState(false);
  const [exportError, setExportError] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<{ startDate: Date; endDate: Date }>({
    startDate: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000), // 30 days ago
    endDate: new Date()
  });

  const handleDateRangeChange = useCallback((startDate: Date, endDate: Date) => {
    setDateRange({ startDate, endDate });
  }, []);

  const handleExport = async () => {
    if (!BACKEND_URL) {
      setExportError('Backend URL is not defined');
      return;
    }

    setIsExporting(true);
    setExportError(null);

    try {
      const response = await fetch(`${BACKEND_URL}/api/export-events`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          startDate: dateRange.startDate.toISOString(),
          endDate: dateRange.endDate.toISOString(),
        }),
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to export events');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'calendar_events.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Export error:', error);
      setExportError('Failed to export events');
    } finally {
      setIsExporting(false);
    }
  };

  const [openFaq, setOpenFaq] = useState<number | null>(null);

  const toggleFaq = (index: number): void => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const faqItems: FaqItem[] = [
    {
      question: "How does the Google Calendar to Excel export work?",
      answer: "Our tool securely connects to your Google Calendar, retrieves your events, and formats them into an Excel or CSV file. You can customize the date range and event details to export."
    },
    {
      question: "Is my calendar data safe?",
      answer: "Absolutely. We prioritize your privacy and security. We only access your calendar data during the export process and do not store any of your information on our servers."
    },
    {
      question: "Can I automate the export process?",
      answer: "Yes! You can set up recurring exports of calendar events in TimeCamp product. This feature is perfect for regular reporting and analysis."
    }
  ];

  const benefits: Benefit[] = [
    { icon: <Clock className="w-6 h-6" />, text: "Save time on manual data entry" },
    { icon: <FileSpreadsheet className="w-6 h-6" />, text: "Analyze your time usage in Excel" },
    { icon: <ShieldCheck className="w-6 h-6" />, text: "Secure and privacy-focused" }
  ];

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center p-4">
      <div className="min-h-screen bg-gray-100 py-10 flex flex-col justify-center sm:py-12">
        <div className="relative py-8 ">
          <div className="absolute inset-0 bg-gradient-to-r from-cyan-400 to-light-blue-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
          <div className="relative px-8 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
            
      <h1 className="text-4xl font-bold mb-4">Export Google Calendar to Excel/CSV</h1>
      <p className="text-lg mb-6 max-w-2xl mx-auto">
        Unlock the power of your calendar data!
        Effortlessly export your Google Calendar events to Excel or CSV. 
        <span className="bg-yellow-300 text-black rounded px-1 m-1">No signup required.</span> 
        Just login with your Google account and export your events in seconds.
      </p>

      <GoogleAuthButton onLogin={login} isAuthorized={isAuthorized} />
      <p className="text-sm text-gray-600">
        1 382 people exported their calendars last month<br />
      </p>
      {authError && <p className="text-red-500 mt-2">{authError}</p>}
      <div className="my-8">
        <DateRangePicker 
          onDateRangeChange={handleDateRangeChange}
          initialStartDate={dateRange.startDate}
          initialEndDate={dateRange.endDate}
        />
      </div>
      {exportError && <p className="text-red-500 mt-2">{exportError}</p>}
      <button
        onClick={handleExport}
        className={`bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-6 rounded-lg mt-4 ${isExporting || !isAuthorized ? 'opacity-50 cursor-not-allowed' : ''}`}
        disabled={isExporting || !isAuthorized}
      >
        {isExporting ? 'Processing...' : 'Export Events'}
      </button>

      <br />
      <br />
      <hr />
      <br />
      <br />

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        {benefits.map((benefit, index) => (
          <div key={index} className="flex flex-col items-center">
            {benefit.icon}
            <p className="mt-2">{benefit.text}</p>
          </div>
        ))}
      </div>

      
      <br />
      <br />

      <h2 className="text-2xl font-bold mb-4">Frequently Asked Questions</h2>
      <div className="w-full max-w-2xl">
        {faqItems.map((item, index) => (
          <div key={index} className="mb-4 border-b border-gray-200 pb-2">
            <button
              className="flex justify-between items-center w-full text-left font-semibold"
              onClick={() => toggleFaq(index)}
            >
              {item.question}
              {openFaq === index ? <ChevronUp /> : <ChevronDown />}
            </button>
            {openFaq === index && <p className="mt-2">{item.answer}</p>}
          </div>
        ))}
      </div>

      <div className="mt-8 text-sm">
        <a href="https://www.timecamp.com/privacy-policy/" className="text-blue-600 hover:underline mr-4">Privacy Policy</a>
        <a href="https://www.timecamp.com" className="text-blue-600 hover:underline mr-4">Powered by TimeCamp</a>
        <a href="mailto:k.rudnicki+calendar@timecamp.com" className="text-blue-600 hover:underline">Contact</a>
      </div>

          </div>
        </div>
      </div>
    </div>
  );
};